import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';

import { ThemeProvider } from '@mui/material';
import defaultTheme from './configs/theme.config';
import ViewerPage from './components/pages/ViewerLayoutTwo.component';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <ViewerPage />
    </ThemeProvider>
  </React.StrictMode>
);
