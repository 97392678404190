import { BaseTemplate } from "../templates/BaseTemplate.component";
import { useMachine } from "@xstate/react";
import initializeViewerMachine from "../../machines/initializeViewer.machine";
import { service } from "../../service";
import { Alert, Box, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import ProgressbarWithMessage from "../molecules/ProgressbarWithMessage.component";
import AuthenticationForm from "../molecules/AuthenticationForm.component";
import { useTranslation } from "react-i18next";
import PrimaryHeader from "../organisms/PrimaryHeader.component";
import PlanHeader from "../organisms/PlanHeader.component";
import ViewerTree from "../molecules/ViewerTree.component";
import { IViewerTreeNode } from "../../interfaces/IViewerTree.interface";
import parse from 'html-react-parser';
import { LEVEL_IDS } from "../../constants/levelIds.constans";
import { Variant } from "@mui/material/styles/createTypography";
import OpenLayersMap from "../organisms/OpenLayersMap.component";
import { useState } from "react";
import MapButton from "../atoms/MapButton";
/**
 * Renders the viewer's main page
 */
const ViewerPage = (): JSX.Element => {
    const { t } = useTranslation();
    const [state, send] = useMachine(initializeViewerMachine, {
        services: {
            isTokenPresent: () => service.isTokenPresent(),
            authenticate: async (ctx) => await service.authenticate({ 
                token: ctx.form.token, 
                password: ctx.form.password 
            }),
        }
    });

    // States
    const [hasFetchCordinatesError] = useState<boolean>(false);

    /**
     * Render the children in the dialog
     */
    const renderDialogChild = () => {
        const { form, errorMsg, viewer } = state.context;
        const formHasRequiredValues = form.password && form.token;

        if (state.matches('Checking for token')) {
            return null;
        }
        if (state.matches('Show error message')) {
            return <Alert severity="error">{errorMsg}</Alert>;;
        }
        if (state.matches('Authentication form') || 
            state.matches('Authentication form.Show password input') ||
            !formHasRequiredValues ||
            !viewer.meta) {
            return (
                <AuthenticationForm 
                    isLoading={state.matches('Authenticating')}
                    submittedTimes={form.submittedTimes}
                    onSubmit={(event) => {
                        event.preventDefault();
                        send({ type: 'Submit authentication form' });
                    }}
                    onPasswordInputChange={(event) => {
                        send({ type: 'Password input changed', value: event.target.value })
                    }}
                />
            );
        }
        if (state.matches('Authenticating') && 
            formHasRequiredValues && 
            viewer.meta) {
            return <ProgressbarWithMessage msg={t('INITIALIZATION.AUTHENTICATING_STEP')} />;
        }
    };

    /**
     * Returns the typography element with the right properties for the viewer node
     */
    const getTitleElement = (node: IViewerTreeNode): JSX.Element => {
        let typographyProps: { variant: Variant, fontSize: number, my: number } = {
            variant: "h5",
            fontSize: 10,
            my: 10
        }
        
        switch (node.data.levelId) {
            case LEVEL_IDS.LICHAAM:
                typographyProps = { variant: 'h1', fontSize: 22, my: 5 };
                break;
            case LEVEL_IDS.REGELS:
                typographyProps = { variant: 'h2', fontSize: 20, my: 4 };
                break;
            case LEVEL_IDS.HOOFDSTUK:
                typographyProps = { variant: 'h3', fontSize: 20, my: 3 };
                break;
            case LEVEL_IDS.AFDELING:
                typographyProps = { variant: 'h3', fontSize: 20, my: 2.8 };
                break;
            case LEVEL_IDS.ARTIKEL:
                typographyProps = { variant: 'h5', fontSize: 19, my: 2 };
                break;
            case LEVEL_IDS.LID:
                typographyProps = { variant: 'h6', fontSize: 15, my: 1.8 };
                break;
            case LEVEL_IDS.PARAGRAAF:
                typographyProps = { variant: 'h4', fontSize: 18, my: 2.8 };
                break;
            case LEVEL_IDS.SUBPARAGRAAF:
                typographyProps = { variant: 'h5', fontSize: 17, my: 2.4 };
                break;
            case LEVEL_IDS.SUBSUBPARAGRAAF:
                typographyProps = { variant: 'h5', fontSize: 16, my: 2.4 };
                break;
            case LEVEL_IDS.TITEL:
                typographyProps = { variant: 'h3', fontSize: 20, my: 4.5 };
                break;
            case LEVEL_IDS.BIJLAGE_ROOT:
                typographyProps = { variant: 'h5', fontSize: 20, my: 4 };
                break;
            case LEVEL_IDS.BIJLAGE_CHILDREN:
                typographyProps = { variant: 'h5', fontSize: 19, my: 2 };
                break;
            case LEVEL_IDS.TOELICHTING:
                typographyProps = { variant: 'h2', fontSize: 20, my: 4 };
                break;
            case LEVEL_IDS.ALGEMENE_TOELICHTING_PARENT:
                typographyProps = { variant: 'h3', fontSize: 19, my: 3 };
                break;
            case LEVEL_IDS.ALGEMENE_TOELICHTING_CHILDREN:
                typographyProps = { variant: 'h4', fontSize: 18, my: 2 };
                break;
            case LEVEL_IDS.ARTIKELSGEWIJZE_TOELICHTING_ROOT:
                typographyProps = { variant: 'h3', fontSize: 19, my: 3 };
                break;
            case LEVEL_IDS.ARTIKELSGEWIJZE_TOELICHTING_CHILDREN:
                typographyProps = { variant: 'h4', fontSize: 18, my: 2 };
                break;
            case LEVEL_IDS.TOELICHTING_ARTIKEL:
                typographyProps = { variant: 'h5', fontSize: 19, my: 2 };
                break;
            }

        if (node.data.levelId === LEVEL_IDS.LID) {
            return <Typography {...typographyProps}>Lid {node.data.title}</Typography>
        }

        return <Typography {...typographyProps}>{node.data.title}</Typography>
    }

    /**
     * Returns the MapButton when node should have it
     */

    const getMapButton = (node: IViewerTreeNode): JSX.Element => {
      if (
        node.data.geo.extent !== "" &&
        node.data.geo.locatie_prime_id !== null &&
        node.data.geo.locatie_prime_id.length
      ) {
        const CBS = state.context.viewer.meta ? state.context.viewer.meta.decisionCbs : "";
        const regelingId = state.context.viewer.meta ? state.context.viewer.meta.decisionRegelingId : "";
        const decisionId = state.context.viewer.meta ? state.context.viewer.meta.decisionId : "";
        const BBOX = node.data.geo.extent ? node.data.geo.extent : "";
        const locatiePrimeIdAll = node.data.geo.locatie_prime_id_all ? node.data.geo.locatie_prime_id_all : "";
        // const mapId = String(`${decisionId}_${regelingId}`);
        
        if (CBS === "" || regelingId === "" || decisionId === "" || BBOX === "")
          return <></>;
        return <MapButton
            regelingId={regelingId}  
            BBOX={BBOX} CBS={CBS}
            locatie_prime_id={node.data.geo.locatie_prime_id}
            locatie_prime_id_all={locatiePrimeIdAll}
            />
      }
      else return <></>
    }

    /**
     * Render
     */
    return (
        <BaseTemplate>
            <Dialog open={!state.done}>
                <DialogContent>
                    {renderDialogChild()}
                </DialogContent>
            </Dialog>
            
            <Dialog open={hasFetchCordinatesError}>
                <DialogContent>
                    <Typography>Something went wrong fetching th coordinates</Typography>
                </DialogContent>
            </Dialog>
            <>
                {state.context.viewer.meta && state.context.viewer.tree && (
                    <>
                      {/* Header */}
                      <Grid container rowSpacing={0} columnSpacing={0}>
                        <Grid
                          item
                          rowSpacing={0}
                          columnSpacing={0}
                          xs={3}
                          sx={{
                            overflow: 'hidden',
                            height: {
                              xs: '25vh'
                            }
                          }}>
                          <Box
                            component={'header'}
                            sx={{
                              mb: 2,
                            }}>
                              <PrimaryHeader />
                              <PlanHeader 
                                  title={state.context.viewer.tree.root.data.title || state.context.viewer.meta.decisionImro } 
                              />
                          </Box>
                        </Grid>

                        {/* Map */}
                        <Grid
                          item
                          rowSpacing={0}
                          columnSpacing={0}
                          xs={9}
                          sx={{ 
                            overflow: 'auto',
                            height: {
                              xs: '25vh'
                            }
                          }}>
                          <Box
                            sx={{ 
                              height: {
                                xs: '100%'
                              }
                            }}>
                              {/* <OpenLayersMap selectedItem={selectedItemForCoordinates} /> */}
                              <OpenLayersMap />

                          </Box>
                        </Grid>

                        {/* Tree */}
                        <Grid
                          item
                          rowSpacing={0}
                          columnSpacing={0}
                          xs={3}
                          sx={{ 
                            overflow: 'auto',
                            height: {
                              xs: '75vh'
                            }
                          }}>
                          <Box>
                            <ViewerTree {...state.context.viewer.tree} />
                          </Box>
                        </Grid>

                        {/* Document */}
                        <Grid
                          item
                          rowSpacing={0}
                          columnSpacing={0}
                          xs={9}
                          sx={{ 
                            overflow: 'auto',
                            height: {
                              xs: '75vh'
                            }
                          }}>
                        <Box p={5}>
                              {Object.values(state.context.viewer.tree).map((node: IViewerTreeNode) => { 
                                  return (
                                      <Box 
                                          key={node.index} 
                                          id={service.normalizeItemIndex({ index: node.index })}
                                          sx={{ 
                                              my: 0, 
                                              py: 0,
                                              pl: 0,
                                              ...((node.data.levelId === LEVEL_IDS.HOOFDSTUK || 
                                                  node.data.levelId === LEVEL_IDS.ALGEMENE_TOELICHTING_PARENT ||
                                                  node.data.levelId === LEVEL_IDS.ARTIKELSGEWIJZE_TOELICHTING_ROOT ||
                                                  node.data.levelId === LEVEL_IDS.TOELICHTING ||
                                                  node.data.levelId === LEVEL_IDS.BIJLAGE_ROOT) && { 
                                                      borderTop: '1px solid', 
                                                      borderTopStyle: "dashed" 
                                                  }
                                              ),
                                          }}
                                      >
                                          {getTitleElement(node)}
                                          {getMapButton(node)}
                                          <Box sx={{ 
                                              fontSize: 14, 
                                              lineHeight: 1.4,
                                              mb: 2,
                                              '> p': {
                                                  my: 0,
                                              },
                                              'td': {
                                                  border: '1px solid black'
                                              },
                                              'table': {
                                                  width: '100% !important',
                                                  mt: 1
                                              }
                                          }}>
                                                  {parse(node.data.text || "")}
                                                  {node.data.text === null && node.data.levelId === LEVEL_IDS.LID && (
                                                      parse("<p>Gereserveerd</p>")
                                                  )}
                                          </Box>
                                      </Box>
                                  )
                              })}
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                )}
            </>
        </BaseTemplate>
    )
};

export default ViewerPage;
