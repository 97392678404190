import { Box } from '@mui/system'
import { IBaseTemplate } from './interfaces/IBaseTemplate.interface';

/**
 * Renders the base template
 * @returns a base template component
 */
const BaseTemplate = (props: IBaseTemplate): JSX.Element => {
  const { children } = props;

  return (
    <>
        <Box component={'main'}>
            {children}
        </Box>
    </>
  )
}

export { BaseTemplate }
